import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AuthService } from './g/controllers/Auth';
import { ConfigService } from './g/controllers/Config';
import { SettingService } from './g/controllers/Setting';
import { UserService } from './g/controllers/User';
import { I18NService } from './g/controllers/I18N';
import { ProfileService } from './g/controllers/Profile';
import { DictionaryService } from './g/controllers/Dictionary';
import { SharedModule } from '../app/shared/shared.module';
import { RoleService } from './g/controllers/Role';
import { ExportService } from './export/export.service';
import { DataSyncService } from './g/controllers/DataSync';
import { BackgroundEventService } from './background-event/background-event.service';
import { UserCardService } from './usercard/usercard.service';
import { ProjectService } from './g/controllers/Project';
import { ImportService } from './import/import.service';
import { LocationService } from './g/controllers/Location';
import { ItemService } from './g/controllers/Item';
import { ParticipantService } from './g/controllers/Participant';
import { StatusService } from './g/controllers/Status';
import { StatisticsService } from './g/controllers/Statistics';
import { FaillistService } from './g/controllers/Faillist';
import { ComparisonService } from './g/controllers/Comparison';
import { CreateTaskService } from './g/controllers/CreateTask';
import { ReportsService } from './g/controllers/Reports';
import { PermissionsService } from './g/controllers/Permissions';
import { DatasyncQueueStatusService } from './background-event/datasyncqueue-status.service';
import { ComparisonQueueStatusService } from './background-event/comparison-queue-status.service';
import { ActiveTasksService } from './g/controllers/ActiveTasks';


@NgModule({
    imports: [
        HttpClientModule,
        SharedModule
    ],
    providers: [
        /* backend proxy-k */
        AuthService,
        ConfigService,
        I18NService,

        ProfileService,
        SettingService,
        UserService,
        DictionaryService,
        RoleService,
        ExportService,
        DataSyncService,
        BackgroundEventService,
        UserCardService,
        ProjectService,
        ImportService,
        LocationService,
        ItemService,
        ParticipantService,
        StatusService,
        StatisticsService,
        FaillistService,
        ComparisonService,
        CreateTaskService,
        ActiveTasksService,
        ReportsService,
        PermissionsService,
        DatasyncQueueStatusService,
        ComparisonQueueStatusService
    ]
})
export class ApiModule { }
